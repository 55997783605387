import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { ToastContainer } from 'react-toastify';
import Footer from "../Footer";
import Header from "../Header/Header";
import { Helmet } from "react-helmet";

// import SideBarContents from '../SideBarContents';

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#ed0600" />
        <meta name="facebook-domain-verification" content="o2g07qj3q2hiknmfrk9e5vbldjrpnk" />
        <script
					src="https://code.jquery.com/jquery-3.3.1.min.js"
					integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8="
					crossOrigin="anonymous"
				/>
      </Helmet>
      <Header location={location} siteTitle={data.site.siteMetadata.title} />
      {/* <SideBarContents /> */}
      <div className="has-fixed-header">
        <main>{children}</main>
      </div>
      <Footer />
      <ToastContainer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
