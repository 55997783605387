import React, { useState } from "react";
import FooterStyles from "./Footer.module.scss";
import { Link } from "gatsby";
import FooterAccordion from "../FooterAccordion";
import { Helmet } from "react-helmet";
import NewsLetterPopup from "../NewsLetterPopup";

function Footer() {
  const [showNewsLetterModal, setShowNewsLetterModal] = useState(false);

  function closeNewsLetterModal() {
    setShowNewsLetterModal(false);
  }

  function openNewsLetterModal() {
    setShowNewsLetterModal(true);
  }

  return (
    <footer>
      <Helmet>
        <script type="text/javascript">
          {`(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias = n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q = e[e.visitorGlobalObjectAlias].q || []).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
          vgo('setAccount', '91248824');
          vgo('setTrackByDefault', true);

          vgo('process');`}
        </script>
      </Helmet>
      <div className={FooterStyles.FooterAccrdionWrapper}>
        <FooterAccordion />
        <div className={FooterStyles.footerContainer}>
          <div className={FooterStyles.footerBody}>
            <ul className={FooterStyles.nonAccordion}>
              <li className={FooterStyles.title}>Company</li>
              <li>
                <Link className={FooterStyles.link} to="/About">
                  About
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/join-us">
                  Join Us
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/Contact">
                  Contact Us
                </Link>
              </li>
              {/*<li>
              <Link className={FooterStyles.link} to="#">Career</Link>
            </li>*/}
            </ul>
            <ul className={FooterStyles.nonAccordion}>
              <li className={FooterStyles.title}>Our Work</li>
              <li>
                <Link className={FooterStyles.link} to="#">
                  Case Study - Highlight
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="#">
                  Projects Search
                </Link>
              </li>
            </ul>
            <ul className={FooterStyles.nonAccordion}>
              <li className={FooterStyles.title}>Solutions</li>
              <li>
                <Link className={FooterStyles.link} to="/web-experiences">
                  Web Experiences
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/ux-design">
                  UX Design
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/mobile-solutions">
                  Mobile Solutions
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/e-commerce">
                  eCommerce
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/product-documentation">
                  Product Documentation
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/online-communities">
                  Online Communities
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/support-tools-portals">
                  Support Tools and Portals
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/product-development">
                  Product Development
                </Link>
              </li>
            </ul>
            <ul className={FooterStyles.nonAccordion}>
              <li className={FooterStyles.title}>Platforms</li>
              <li>
                <Link
                  className={FooterStyles.link}
                  to="/adobe-experience-manager"
                >
                  Adobe Experience Manager
                </Link>
              </li>
              <li>
                <Link
                  className={FooterStyles.link}
                  to="/adobe-experience-manager-forms"
                >
                  Adobe Experience Forms
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/adobe-analytics">
                  Adobe Analytics
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/adobe-target">
                  Adobe Target
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/adobe-campaign">
                  Adobe Campaign
                </Link>
              </li>
              <li>
                <Link
                  className={FooterStyles.link}
                  to="/adobe-commerce-magento"
                >
                  Adobe Commerce and Magento
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/jam-stack">
                  JAM Stack
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/headless-cms">
                  Headless-CMS
                </Link>
              </li>
              <li>
                <Link
                  className={FooterStyles.link}
                  to="/salesforce-experience-cloud"
                >
                  Salesforce Experience Cloud
                </Link>
              </li>
              {/* <li>
							<Link className={FooterStyles.link} to="/#platforms">
								Adobe
							</Link>
						</li>
						<li>
							<Link className={FooterStyles.link} to="/#platforms">
								Salesforce
							</Link>
						</li> */}
              {/*<li>
              <Link className={FooterStyles.link} to="/#platforms">Azure</Link>
            </li>*/}
              {/* <li>
							<Link className={FooterStyles.link} to="/#platforms">
								AWS
							</Link>
						</li>
						<li>
							<Link className={FooterStyles.link} to="/#platforms">
								Google
							</Link>
						</li> */}
            </ul>
            <ul className={FooterStyles.nonAccordion}>
              <li className={FooterStyles.title}>Labs</li>
              <li>
                <Link
                  className={FooterStyles.link}
                  to="/adobe-experience-manager"
                >
                  Adobe Experience Manager
                </Link>
              </li>
              <li>
                <Link
                  className={FooterStyles.link}
                  href="../../Hashout-Guide-to-connecting-AEM-and-CRM-for-self-service.pdf"
                  target="_blank"
                >
                  AEM-Salesforce Connector
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="#">
                  Custom Event Framework
                </Link>
              </li>
              <li>
                <Link
                  className={FooterStyles.link}
                  href="https://sitecheck.hashouttech.com/"
                  target="_blank"
                >
                  Broken Link Checker
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/virtual-tradeshow">
                  Virtual Tradeshow
                </Link>
              </li>
              {/* <li>
							<Link className={FooterStyles.link} to="/blog">
								Blogs
							</Link>
						</li>
						<li>AEM - Salesforce Connector</li>
						<li>Content Analyzer</li> */}
            </ul>
            <ul className={FooterStyles.nonAccordion}>
              <li className={FooterStyles.title}>Blogs</li>
              <li>
                <Link className={FooterStyles.link} to="#">
                  Technology
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="#">
                  AI
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="#">
                  AEM
                </Link>
              </li>
              <li>
                <Link className={FooterStyles.link} to="/blog">
                  All Blogs
                </Link>
              </li>
            </ul>
          </div>
          <div className={FooterStyles.footerContactSection}>
            <ul className={FooterStyles.contact}>
              <li className={FooterStyles.address}>US Sales</li>
              <li>
                <a href="tel:+1-7036221689">+1 (703) 622-1689</a>
              </li>
              <li className={FooterStyles.contactEmail}>
                <a href="mailto:millie.mathew@hashouttech.com">
                  millie.mathew@hashouttech.com
                </a>
              </li>
              {/* <li>5725 Center Square Dr, Suite 200B, Centreville VA 20120</li> */}
            </ul>
            <ul className={FooterStyles.contact /*indianAddress*/}>
              <li className={FooterStyles.address}>India Sales </li>
              <li>
                <a href="tel:+91-9886700898">+91 98867 00898</a>
              </li>
              <li className={FooterStyles.contactEmail}>
                <a href="mailto:chella.kumar@hashouttech.com">
                  chella.kumar@hashouttech.com
                </a>
              </li>
              {/* <li>
							91Springboard, 80 Feet Rd, Koramangala 8th Block, <br />
							Bengaluru, Karnataka 560095
						</li> */}
            </ul>
            <ul className={FooterStyles.contact}>
              <li className={FooterStyles.address}>Australia Sales</li>
              <li>
                <a href="tel:+61 478136437">+61 478 136 437</a>
              </li>
              <li className={FooterStyles.contactEmail}>
              <a href="mailto:chella.kumar@hashouttech.com">
                  chella.kumar@hashouttech.com
                </a>
              </li>
            </ul>
            <ul className={FooterStyles.contact}>
              <li className={FooterStyles.smImageWrapper}>
                <a href="https://www.facebook.com/hashouttech" target="_blank">
                  <img src="../../Fb.svg"></img>
                </a>
                {/*<img src="../../twitter.svg"></img>*/}
                <a
                  href="https://www.linkedin.com/company/hashout-technologies/"
                  target="_blank"
                >
                  <img src="../../linkedin.svg"></img>
                </a>
                <a href="https://twitter.com/hashouttech" target="_blank">
                  <img src="../../twitter.svg"></img>
                </a>
              </li>
            </ul>
            {/* <ul className={FooterStyles.indianAddress}> */}
            {/* <li className={FooterStyles.address}>India Address </li>
            <li>
              91Springboard, 80 Feet Rd, Koramangala 8th Block, <br />
              Bengaluru, Karnataka 560095
            </li> */}
            {/* <div className={FooterStyles.modalFooter}>
							<button type="button" onClick={openNewsLetterModal}>
								Newsletter Signup
							</button>
							<NewsLetterPopup
								showNewsLetterModal={showNewsLetterModal}
								closeNewsLetterModal={closeNewsLetterModal}
							/>
						</div> */}
            {/* </ul> */}
          </div>
          <div className={FooterStyles.footerBottom}>
            <p>
              Copyright © 2020 - Hashout Technologies Inc
              {/* <span>
							<span className={FooterStyles.pipe}>|</span>
							<Link to="/privacy">Privacy Policy</Link>
							<span className={FooterStyles.pipe}>|</span>
							<Link to="/cookie">Cookie Policy</Link>
						</span> */}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
