import React, { useState, useRef } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import "./footerAccordion.scss";
import FooterAccordionStyles from "./footerAccordion.module.scss";
import { Link } from "gatsby";
function FooterAccordion() {
  return (
    <Accordion allowZeroExpanded={true}>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <p className={FooterAccordionStyles.title}>Company</p>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ul className={FooterAccordionStyles.accordionLinks}>
            <li>
              <Link className={FooterAccordionStyles.link} to="/About">
                About
              </Link>
            </li>
            {/*<li>
          <Link className={FooterAccordionStyles.link} to="#">Career</Link>
        </li>*/}
            <li>
              <Link className={FooterAccordionStyles.link} to="/join-us">
                Join Us
              </Link>
            </li>
            <li>
              <Link className={FooterAccordionStyles.link} to="/Contact">
                Contact Us
              </Link>
            </li>
          </ul>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <p className={FooterAccordionStyles.title}>Our Work</p>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ul className={FooterAccordionStyles.accordionLinks}>
            <li>
              <Link className={FooterAccordionStyles.link} to="#">
                Case Study - Highlight
              </Link>
            </li>
            {/*<li>
          <Link className={FooterAccordionStyles.link} to="#">Career</Link>
        </li>*/}
            <li>
              <Link className={FooterAccordionStyles.link} to="#">
                Projects Search
              </Link>
            </li>
          </ul>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <p className={FooterAccordionStyles.title}>Solutions</p>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ul className={FooterAccordionStyles.accordionLinks}>
            <li>
              <Link
                className={FooterAccordionStyles.link}
                to="/web-experiences"
              >
                Web Experiences
              </Link>
            </li>
            <li>
              <Link className={FooterAccordionStyles.link} to="/ux-design">
                UX Design
              </Link>
            </li>
            <li>
              <Link
                className={FooterAccordionStyles.link}
                to="/mobile-solutions"
              >
                Mobile Solutions
              </Link>
            </li>
            <li>
              <Link className={FooterAccordionStyles.link} to="/e-commerce">
                eCommerce
              </Link>
            </li>
            <li>
              <Link
                className={FooterAccordionStyles.link}
                to="/product-documentation"
              >
                Product Documentation
              </Link>
            </li>
            <li>
              <Link
                className={FooterAccordionStyles.link}
                to="/online-communities"
              >
                Online Communities
              </Link>
            </li>
            <li>
              <Link
                className={FooterAccordionStyles.link}
                to="/support-tools-portals"
              >
                Support Tools and Portals
              </Link>
            </li>
            <li>
              <Link
                className={FooterAccordionStyles.link}
                to="/product-development"
              >
                Product Development
              </Link>
            </li>
          </ul>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <p className={FooterAccordionStyles.title}>Platforms</p>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ul className={FooterAccordionStyles.accordionLinks}>
            <li>
              <Link
                className={FooterAccordionStyles.link}
                to="/adobe-experience-manager"
              >
                Adobe Experience Manager
              </Link>
            </li>
            <li>
              <Link
                className={FooterAccordionStyles.link}
                to="/adobe-experience-manager-forms"
              >
                Adobe Experience Forms
              </Link>
            </li>
            <li>
              <Link
                className={FooterAccordionStyles.link}
                to="/adobe-analytics"
              >
                Adobe Analytics
              </Link>
            </li>
            <li>
              <Link className={FooterAccordionStyles.link} to="/adobe-target">
                Adobe Target
              </Link>
            </li>
            <li>
              <Link className={FooterAccordionStyles.link} to="/adobe-campaign">
                Adobe Campaign
              </Link>
            </li>
            <li>
              <Link
                className={FooterAccordionStyles.link}
                to="/adobe-commerce-magento"
              >
                Adobe Commerce and Magento
              </Link>
            </li>
            <li>
              <Link className={FooterAccordionStyles.link} to="/jam-stack">
                JAM Stack
              </Link>
            </li>
            <li>
              <Link className={FooterAccordionStyles.link} to="/headless-cms">
                Headless-CMS
              </Link>
            </li>
            <li>
              <Link
                className={FooterAccordionStyles.link}
                to="/salesforce-experience-cloud"
              >
                Salesforce Experience Cloud
              </Link>
            </li>
          </ul>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <p className={FooterAccordionStyles.title}>Labs</p>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ul className={FooterAccordionStyles.accordionLinks}>
            <li>
              <Link className={FooterAccordionStyles.link} to="#">
                Communities
              </Link>
            </li>
            <li>
              <Link className={FooterAccordionStyles.link} to="#">
                OCM
              </Link>
            </li>
            <li>
              <Link className={FooterAccordionStyles.link} to="#">
                AI Driven Chatbot & Search
              </Link>
            </li>
            {/* <li>
              <Link className={FooterAccordionStyles.link} to="/blog">
                Blogs
              </Link>
            </li>
            <li>AEM - Salesforce Connector</li>
            <li>Content Analyzer</li> */}
          </ul>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <p className={FooterAccordionStyles.title}>Blogs</p>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ul className={FooterAccordionStyles.accordionLinks}>
            <li>
              <Link className={FooterAccordionStyles.link} to="#">
                Technology
              </Link>
            </li>
            <li>
              <Link className={FooterAccordionStyles.link} to="#">
                AI
              </Link>
            </li>
            <li>
              <Link className={FooterAccordionStyles.link} to="#">
                AEM
              </Link>
            </li>
            <li>
              <Link className={FooterAccordionStyles.link} to="#">
                All Blogs
              </Link>
            </li>
          </ul>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default FooterAccordion;
