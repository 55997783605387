import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";

function Header() {
  const [activeNavLink, setActiveNavLink] = useState();
  const [activeNav, setActiveNav] = useState();

  const handleSolutionActiveLinks = (activeLink) => {
    setActiveNavLink(activeLink);
    setActiveNav("solutions");
  };

  const handlePlatformActiveLinks = (activeLink) => {
    setActiveNavLink(activeLink);
    setActiveNav("platforms");
  };

  const handleNavActiveLinks = (activeLink) => {
    setActiveNav(activeLink);
  };
  useEffect(() => {
    $(".header-nav ul li > a:not(:only-child)")
      .off("click")
      .on("click", function (e) {
        $(this).siblings(".nav-drop").slideToggle();
        $(".nav-drop").not($(this).siblings()).hide();
        e.stopPropagation();
      });
    $("html").on("click", function () {
      $(".nav-drop").hide();
    });
    // Toggle open and close nav styles on click
    $("#nav__nav_toggle")
      .off("click")
      .on("click", function () {
        $(".header-nav ul").slideToggle();
      });
    $("#nav__nav_toggle").on("click", function () {
      this.classList.toggle("active");
    });

    if (
      window.location.pathname === "/web-experiences" ||
      window.location.pathname === "/ux-design" ||
      window.location.pathname === "/mobile-solutions" ||
      window.location.pathname === "/e-commerce" ||
      window.location.pathname === "/product-documentation" ||
      window.location.pathname === "/online-communities" ||
      window.location.pathname === "/support-tools-portals" ||
      window.location.pathname === "/product-development"
    ) {
      handleSolutionActiveLinks(window.location.pathname);
    } else if (
      window.location.pathname === "/adobe-experience-manager" ||
      window.location.pathname === "/adobe-experience-manager-forms" ||
      window.location.pathname === "/adobe-analytics" ||
      window.location.pathname === "/adobe-target" ||
      window.location.pathname === "/adobe-campaign" ||
      window.location.pathname === "/adobe-commerce-magento" ||
      window.location.pathname === "/jam-stack" ||
      window.location.pathname === "/headless-cms" ||
      window.location.pathname === "/salesforce-experience-cloud"
    ) {
      handlePlatformActiveLinks(window.location.pathname);
    } else if (
      window.location.pathname === "/join-us" ||
      window.location.pathname === "/blog" ||
      window.location.pathname === "/Contact"
    ) {
      handleNavActiveLinks(window.location.pathname);
    }
  });

  return (
    <header className={`${styles["header"]}`}>
      <section className={`${styles["section"]}`}>
        <div className={`${styles["navigation"]}`}>
          <div className={`${styles["navigation__nav_container"]}`}>
            <div className={`${styles["navigation__header"]}`}>
              <a href="/">
                <img src={`../../Logo.svg`} alt="HASHOUT" />
              </a>
            </div>
            <nav className={`${styles["nav"]} header-nav`}>
              {/* <div className={`${styles["nav__search_mobile"]}`}>
                <a
                  className={`${styles["hidden_md_up"]}`}
                  href="javascript:void(0);"
                >
                  <img src="../../solutions/search-mobile.png" alt="Search" />
                </a>
                <a
                  className={`${styles["hidden_md_down"]}`}
                  href="javascript:void(0);"
                >
                  <img src="../../solutions/search.png" alt="Search" />
                </a>
              </div> */}
              <div className={`${styles["nav__nav_mobile"]}`}>
                <a
                  className={`${styles["nav__nav_toggle"]}`}
                  id="nav__nav_toggle"
                  href="javascript:void(0);"
                >
                  <img src="../../solutions/menu.png" alt="Search" />
                </a>
              </div>
              <ul className={`${styles["nav_list"]}`}>
                {/* <li className={`${styles["nav_list__list"]}`}>
									<a
										className={`${styles["nav_list__link"]}`}
										href="javascript:void(0);"
									>
										Our work
									</a>
								</li> */}
                <li
                  className={`${styles["nav_list__list"]} ${
                    activeNav === "solutions" ? styles["active"] : ""
                  }`}
                >
                  <a
                    className={`${styles["nav_list__link"]}`}
                    href="javascript:void(0);"
                  >
                    Solutions
                  </a>
                  <ul className={`${styles["nav_dropdown"]} nav-drop`}>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/web-experiences"
                          ? styles["active"]
                          : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]}`}
                        href="/web-experiences"
                      >
                        Web Experiences
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/ux-design" ? styles["active"] : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]}`}
                        href="/ux-design"
                      >
                        UX Design
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/mobile-solutions"
                          ? styles["active"]
                          : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]}`}
                        href="/mobile-solutions"
                      >
                        Mobile Solutions
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/e-commerce" ? styles["active"] : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]}`}
                        href="/e-commerce"
                      >
                        E-Commerce
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/product-documentation"
                          ? styles["active"]
                          : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]}`}
                        href="/product-documentation"
                      >
                        Product Documentation
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/online-communities"
                          ? styles["active"]
                          : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]}`}
                        href="/online-communities"
                      >
                        Online Communities
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/support-tools-portals"
                          ? styles["active"]
                          : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]}`}
                        href="/support-tools-portals"
                      >
                        Support Tools & Portals
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/product-development"
                          ? styles["active"]
                          : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]}`}
                        href="/product-development"
                      >
                        Product Development
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  className={`${styles["nav_list__list"]} ${
                    activeNav === "platforms" ? styles["active"] : ""
                  }`}
                >
                  <a
                    className={`${styles["nav_list__link"]}`}
                    href="javascript:void(0);"
                  >
                    Platforms
                  </a>
                  <ul className={`${styles["nav_dropdown"]} nav-drop`}>
                    <li className={`${styles["nav_dropdown__list"]}`}>
                      <a
                        className={`${styles["nav_dropdown__link"]} ${styles["nav_dropdown__link__heading"]}`}
                        href="javascript:void(0);"
                      >
                        Adobe Experience Cloud
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/adobe-experience-manager"
                          ? styles["active"]
                          : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]} ${styles["nav_dropdown__link__indent"]}`}
                        href="/adobe-experience-manager"
                      >
                        Adobe Experience Manager
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/adobe-experience-manager-forms"
                          ? styles["active"]
                          : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]} ${styles["nav_dropdown__link__indent"]}`}
                        href="/adobe-experience-manager-forms"
                      >
                        Adobe - Forms
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/adobe-analytics"
                          ? styles["active"]
                          : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]} ${styles["nav_dropdown__link__indent"]}`}
                        href="/adobe-analytics"
                      >
                        Adobe Analytics
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/adobe-target"
                          ? styles["active"]
                          : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]} ${styles["nav_dropdown__link__indent"]}`}
                        href="/adobe-target"
                      >
                        Adobe Target
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/adobe-campaign"
                          ? styles["active"]
                          : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]} ${styles["nav_dropdown__link__indent"]}`}
                        href="/adobe-campaign"
                      >
                        Adobe Campaign
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/adobe-commerce-magento"
                          ? styles["active"]
                          : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]}`}
                        href="/adobe-commerce-magento"
                      >
                        Adobe Commerce and Magento
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/jam-stack" ? styles["active"] : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]}`}
                        href="/jam-stack"
                      >
                        JAMStack
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/headless-cms"
                          ? styles["active"]
                          : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]}`}
                        href="/headless-cms"
                      >
                        Headless CMS
                      </a>
                    </li>
                    <li
                      className={`${styles["nav_dropdown__list"]} ${
                        activeNavLink === "/salesforce-experience-cloud"
                          ? styles["active"]
                          : ""
                      }`}
                    >
                      <a
                        className={`${styles["nav_dropdown__link"]}`}
                        href="/salesforce-experience-cloud"
                      >
                        Salesforce Experience Cloud
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  className={`${styles["nav_list__list"]} ${
                    activeNav === "/join-us" ? styles["active"] : ""
                  }`}
                >
                  <a className={`${styles["nav_list__link"]}`} href="/join-us">
                    Join us
                  </a>
                </li>
                <li
                  className={`${styles["nav_list__list"]} ${
                    activeNav === "/blog" ? styles["active"] : ""
                  }`}
                >
                  <a className={`${styles["nav_list__link"]}`} href="/blog">
                    Blogs
                  </a>
                </li>
                <li
                  className={`${styles["nav_list__list"]} ${
                    activeNav === "/Contact" ? styles["active"] : ""
                  }`}
                >
                  <a className={`${styles["nav_list__link"]}`} href="/Contact">
                    Contact us
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
    </header>
  );
}
export default Header;
